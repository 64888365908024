import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_IonSplitPane = _resolveComponent("IonSplitPane")!
  const _component_InfoWatiosWatCoins = _resolveComponent("InfoWatiosWatCoins")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ProfileMobileBox = _resolveComponent("ProfileMobileBox")!
  const _component_Redeem = _resolveComponent("Redeem")!
  const _component_NotificationsModal = _resolveComponent("NotificationsModal")!
  const _component_InviteFriendsModal = _resolveComponent("InviteFriendsModal")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, null, {
    default: _withCtx(() => [
      _createVNode(_component_IonSplitPane, { "content-id": "main-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet, { id: "main-content" })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_ion_modal, {
        "is-open": _ctx.recordStore.state.recordState.showinfoWatiosWatCoins,
        "enter-animation": _ctx.enterInfoWatAnimation,
        "leave-animation": _ctx.leaveInfoWatAnimation,
        "css-class": "infoWatiosWatCoinsModal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InfoWatiosWatCoins, {
            ref: "infoWatModal",
            id: "infoWatiosWatCoinsContainer"
          }, null, 512 /* NEED_PATCH */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["is-open", "enter-animation", "leave-animation"]),
      _createVNode(_component_ion_modal, {
        id: "profileMobileBoxModal",
        "is-open": _ctx.configurationStore.state.showProfileBox,
        "enter-animation": _ctx.enterProfileMobileBoxAnimation,
        "leave-animation": _ctx.leaveProfileMobileBoxAnimation,
        "css-class": "profileMobileBoxModal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ProfileMobileBox, {
            ref: "profileModal",
            id: "profileMobileBoxContainer"
          }, null, 512 /* NEED_PATCH */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["is-open", "enter-animation", "leave-animation"]),
      _createVNode(_component_ion_modal, {
        "is-open": _ctx.exchangeStore.state.redeemBox.showRedeem,
        "css-class": "redeemModal",
        onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeRedeem()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Redeem, { id: "redeemContainer" })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["is-open"]),
      _createVNode(_component_ion_modal, {
        "is-open": _ctx.notifStore.state.showNotifications,
        "css-class": "notificationsModal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NotificationsModal, {
            ref: "notificationsModal",
            id: "notificationsModalContainer"
          }, null, 512 /* NEED_PATCH */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["is-open"]),
      _createVNode(_component_ion_modal, {
        "is-open": _ctx.inviteFriendsStore.state.showInviteFriendsModal,
        onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inviteFriendsStore.showInviteFriends(false))),
        "css-class": "inviteFriendsModal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InviteFriendsModal, {
            id: _ctx.userData.identifier
          }, null, 8 /* PROPS */, ["id"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["is-open"])
    ]),
    _: 1 /* STABLE */
  }))
}